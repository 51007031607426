import {
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { FC } from 'react';
import { EmailForm } from '../components/EmailForm';
import { Text } from '../components/Text';
import classes from './contact.module.css';

export const Contact: FC = () => (
  <Text>
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <h1>Contact</h1>
        <address>
          <Space size={6}>
            <MailOutlined />{' '}
            <a href="mailto:info@helderaccuservice.nl">
              info@helderaccuservice.nl
            </a>
          </Space>
          <br />
          <Space size={6}>
            <PhoneOutlined /> <a href="tel:+31645892078">(06) 4589 2078</a>
          </Space>
          <br />
          <Space size={6}>
            <WhatsAppOutlined />{' '}
            <a
              href="https://wa.me/31645892078"
              target="_blank"
              rel="noreferrer"
            >
              (06) 4589 2078
            </a>
          </Space>
        </address>
        <h2>Openingstijden</h2>
        <address>
          <p>
            Maandag: 9.00 - 16.00u
            <br />
            Dinsdag: Op afspraak
            <br />
            Woensdag: 9.00 - 16.00u
            <br />
            Donderdag: 9.00 - 16.00u
            <br />
            Vrijdag: 9.00 - 16.00u
          </p>
        </address>
        <h2>Adres</h2>
        <address>
          <p>
            Van Speykstraat 5
            <br />
            1782 RA Den Helder
          </p>
        </address>
        <div className={classes.map}>
          <div className={classes.canvas}>
            <iframe
              title="Google Maps"
              src="https://maps.google.com/maps?q=van%20speykstraat%205%20den%20helder&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            ></iframe>
            <br />
          </div>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <h1>E-mail</h1>
        <EmailForm />
      </Col>
    </Row>
  </Text>
);
